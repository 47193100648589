const autoNgTemplateLoaderTemplate1 = require('./summary.html');

angular.module('portainer.kubernetes').component('kubernetesSummaryView', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: 'KubernetesSummaryController',
  controllerAs: '$ctrl',
  bindings: {
    formValues: '<',
    oldFormValues: '<',
  },
});
