const autoNgTemplateLoaderTemplate1 = require('./auto-user-provision-toggle.html');

export const autoUserProvisionToggle = {
  templateUrl: autoNgTemplateLoaderTemplate1,
  transclude: {
    description: 'fieldDescription',
  },
  bindings: {
    ngModel: '=',
  },
};
