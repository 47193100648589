const autoNgTemplateLoaderTemplate1 = require('./storage-class-switch.html');

import angular from 'angular';
import controller from './storage-class-switch.controller.js';

export const storageClassSwitch = {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller,
  bindings: {
    value: '<',
    onChange: '<',
    name: '<',
  },
};

angular.module('portainer.kubernetes').component('storageClassSwitch', storageClassSwitch);
