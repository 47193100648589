const autoNgTemplateLoaderTemplate1 = require('./kube-registry-access-view.html');

import controller from './kube-registry-access-view.controller';

export const kubernetesRegistryAccessView = {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller,
  bindings: {
    endpoint: '<',
  },
};
