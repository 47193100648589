const autoNgTemplateLoaderTemplate1 = require('./node-availability-select.html');

angular.module('portainer.docker').component('nodeAvailabilitySelect', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: 'NodeAvailabilitySelectController',
  bindings: {
    availability: '<',
    originalValue: '<',
    onSave: '&',
  },
});
