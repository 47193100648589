const autoNgTemplateLoaderTemplate1 = require('./stack-duplication-form.html');

angular.module('portainer.app').component('stackDuplicationForm', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: 'StackDuplicationFormController',
  bindings: {
    onDuplicate: '&',
    onMigrate: '&',
    endpoints: '<',
    groups: '<',
    currentEndpointId: '<',
    yamlError: '<',
  },
});
