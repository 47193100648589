const autoNgTemplateLoaderTemplate1 = require('./registry-details.html');

import angular from 'angular';

export const registryDetails = {
  templateUrl: autoNgTemplateLoaderTemplate1,
  bindings: {
    registry: '<',
  },
};

angular.module('portainer.app').component('registryDetails', registryDetails);
