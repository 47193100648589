const autoNgTemplateLoaderTemplate1 = require('./usersDatatable.html');

import angular from 'angular';
import UsersDatatableController from './usersDatatableController';

angular.module('portainer.app').component('usersDatatable', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: UsersDatatableController,
  bindings: {
    titleText: '@',
    titleIcon: '@',
    dataset: '<',
    tableKey: '@',
    orderBy: '@',
    reverseOrder: '<',
    removeAction: '<',
    authenticationMethod: '<',
    isAdmin: '<',
  },
});
