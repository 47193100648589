const autoNgTemplateLoaderTemplate1 = require('./datatable-columns-visibility.html');

import angular from 'angular';

import controller from './datatable-columns-visibility.controller';

angular.module('portainer.app').component('datatableColumnsVisibility', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller,
  bindings: {
    columns: '<',
    onChange: '<',
  },
});
