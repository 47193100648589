const autoNgTemplateLoaderTemplate1 = require('./ldap-groups-datatable.html');

export const ldapGroupsDatatable = {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: 'GenericDatatableController',
  bindings: {
    titleText: '@',
    titleIcon: '@',
    dataset: '<',
    tableKey: '@',
    orderBy: '@',
    reverseOrder: '<',
  },
};
