const autoNgTemplateLoaderTemplate1 = require('./azureEndpointConfig.html');

export const azureEndpointConfig = {
  bindings: {
    applicationId: '=',
    tenantId: '=',
    authenticationKey: '=',
  },
  templateUrl: autoNgTemplateLoaderTemplate1,
};
