const autoNgTemplateLoaderTemplate1 = require('./oauth-settings.html');

import angular from 'angular';
import controller from './oauth-settings.controller';

angular.module('portainer.oauth').component('oauthSettings', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  bindings: {
    settings: '=',
    teams: '<',
    onSaveSettings: '<',
    saveButtonState: '<',
  },
  controller,
});
