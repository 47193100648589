const autoNgTemplateLoaderTemplate1 = require('./swarm-node-details-panel.html');

angular.module('portainer.docker').component('swarmNodeDetailsPanel', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: 'SwarmNodeDetailsPanelController',
  bindings: {
    details: '<',
    originalNode: '<',
  },
});
