const autoNgTemplateLoaderTemplate1 = require('./ldap-custom-group-search.html');

import controller from './ldap-custom-group-search.controller';

export const ldapCustomGroupSearch = {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller,
  bindings: {
    settings: '=',
    onSearchClick: '<',
    limitedFeatureId: '<',
  },
};
