const autoNgTemplateLoaderTemplate1 = require('./demo-feature-indicator.html');

import angular from 'angular';
import controller from './demo-feature-indicator.controller.js';

export const demoFeatureIndicator = {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller,
  bindings: {
    content: '<',
  },
};

angular.module('portainer.app').component('demoFeatureIndicator', demoFeatureIndicator);
