const autoNgTemplateLoaderTemplate1 = require('./cluster.html');

angular.module('portainer.kubernetes').component('kubernetesClusterView', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: 'KubernetesClusterController',
  controllerAs: 'ctrl',
  bindings: {
    endpoint: '<',
  },
});
