const autoNgTemplateLoaderTemplate1 = require('./ldap-settings-group-dn-builder.html');

import controller from './ldap-settings-group-dn-builder.controller';

export const ldapSettingsGroupDnBuilder = {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller,
  bindings: {
    // ngModel: string (dc=,cn=,)
    ngModel: '<',
    // onChange(string) => void
    onChange: '<',
    // suffix: string (dc=,dc=,)
    suffix: '@',
    // index: int >= 0
    index: '<',
    onRemoveClick: '<',
    limitedFeatureId: '<',
  },
};
