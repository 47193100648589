const autoNgTemplateLoaderTemplate1 = require('./file-upload-form.html');

export const fileUploadForm = {
  templateUrl: autoNgTemplateLoaderTemplate1,

  bindings: {
    file: '<',
    ngRequired: '<',
    onChange: '<',
  },

  transclude: {
    description: '?fileUploadDescription',
  },
};
