const autoNgTemplateLoaderTemplate1 = require('./node-labels-table.html');

angular.module('portainer.docker').component('nodeLabelsTable', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: 'NodeLabelsTableController',
  bindings: {
    labels: '<',
    onChangedLabels: '&',
  },
});
