const autoNgTemplateLoaderTemplate1 = require('./resourcePools.html');

angular.module('portainer.kubernetes').component('kubernetesResourcePoolsView', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: 'KubernetesResourcePoolsController',
  controllerAs: 'ctrl',
  bindings: {
    endpoint: '<',
  },
});
