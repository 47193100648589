const autoNgTemplateLoaderTemplate1 = require('./show-hide.html');

import angular from 'angular';

angular.module('portainer.app').component('showHide', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  bindings: {
    value: '<',
    useAsterisk: '<',
  },
});
