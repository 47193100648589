const autoNgTemplateLoaderTemplate1 = require('./copy-button.html');

import angular from 'angular';
import controller from './copy-button.controller';
import './copy-button.css';

angular.module('portainer.app').component('copyButton', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller,
  bindings: {
    value: '<',
  },
});
