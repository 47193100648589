const autoNgTemplateLoaderTemplate1 = require('./porEndpointSecurity.html');

angular.module('portainer.app').component('porEndpointSecurity', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: 'porEndpointSecurityController',
  bindings: {
    // This object will be populated with the form data.
    // Model reference in endpointSecurityModel.js
    formData: '=',
    // The component will use this object to initialize the default values
    // if present.
    endpoint: '<',
  },
});
