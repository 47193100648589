const autoNgTemplateLoaderTemplate1 = require('./gitlabProjectsDatatable.html');

angular.module('portainer.app').component('gitlabProjectsDatatable', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: 'GitlabProjectsDatatableController',
  bindings: {
    titleText: '@',
    titleIcon: '@',
    dataset: '<',
    tableKey: '@',
    orderBy: '@',
    reverseOrder: '<',
    state: '=',
  },
});
