const autoNgTemplateLoaderTemplate1 = require('./editEdgeGroupView.html');

import angular from 'angular';

import { EditEdgeGroupController } from './editEdgeGroupViewController';

angular.module('portainer.edge').component('editEdgeGroupView', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: EditEdgeGroupController,
});
