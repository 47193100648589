const autoNgTemplateLoaderTemplate1 = require('./deploy.html');

angular.module('portainer.kubernetes').component('kubernetesDeployView', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: 'KubernetesDeployController',
  controllerAs: 'ctrl',
  bindings: {
    endpoint: '<',
  },
});
