const autoNgTemplateLoaderTemplate1 = require('./status-indicator.html');

import angular from 'angular';

import './status-indicator.css';

export const statusIndicator = {
  templateUrl: autoNgTemplateLoaderTemplate1,
  bindings: {
    ok: '<',
  },
};

angular.module('portainer.app').component('statusIndicator', statusIndicator);
