const autoNgTemplateLoaderTemplate1 = require('./createEdgeGroupView.html');

import angular from 'angular';

import { CreateEdgeGroupController } from './createEdgeGroupViewController';

angular.module('portainer.edge').component('createEdgeGroupView', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: CreateEdgeGroupController,
});
