const autoNgTemplateLoaderTemplate1 = require('./hostBrowser.html');

import angular from 'angular';
import { HostBrowserController } from './hostBrowserController';

angular.module('portainer.agent').component('hostBrowser', {
  controller: HostBrowserController,
  templateUrl: autoNgTemplateLoaderTemplate1,
  bindings: {
    endpointId: '<',
  },
});
