import angular from 'angular';

import {
  API_ENDPOINT_AUTH,
  API_ENDPOINT_BACKUP,
  API_ENDPOINT_CUSTOM_TEMPLATES,
  API_ENDPOINT_EDGE_GROUPS,
  API_ENDPOINT_EDGE_JOBS,
  API_ENDPOINT_EDGE_STACKS,
  API_ENDPOINT_EDGE_TEMPLATES,
  API_ENDPOINT_ENDPOINTS,
  API_ENDPOINT_ENDPOINT_GROUPS,
  API_ENDPOINT_KUBERNETES,
  API_ENDPOINT_MOTD,
  API_ENDPOINT_REGISTRIES,
  API_ENDPOINT_RESOURCE_CONTROLS,
  API_ENDPOINT_SETTINGS,
  API_ENDPOINT_STACKS,
  API_ENDPOINT_SUPPORT,
  API_ENDPOINT_USERS,
  API_ENDPOINT_TAGS,
  API_ENDPOINT_TEAMS,
  API_ENDPOINT_TEAM_MEMBERSHIPS,
  API_ENDPOINT_TEMPLATES,
  API_ENDPOINT_WEBHOOKS,
  PAGINATION_MAX_ITEMS,
  APPLICATION_CACHE_VALIDITY,
  CONSOLE_COMMANDS_LABEL_PREFIX,
  PREDEFINED_NETWORKS,
} from './constants';
import { BROWSER_OS_PLATFORM } from './react/constants';

// don't declare new constants, either:
// - if only used in one file or module, declare in that file or module (as a regular js constant)
// - if needed across modules, declare like in `./constants` and use es6 import for that

export const constantsModule = angular
  .module('portainer.app.constants', [])
  .constant('API_ENDPOINT_AUTH', API_ENDPOINT_AUTH)
  .constant('API_ENDPOINT_BACKUP', API_ENDPOINT_BACKUP)
  .constant('API_ENDPOINT_CUSTOM_TEMPLATES', API_ENDPOINT_CUSTOM_TEMPLATES)
  .constant('API_ENDPOINT_EDGE_GROUPS', API_ENDPOINT_EDGE_GROUPS)
  .constant('API_ENDPOINT_EDGE_JOBS', API_ENDPOINT_EDGE_JOBS)
  .constant('API_ENDPOINT_EDGE_STACKS', API_ENDPOINT_EDGE_STACKS)
  .constant('API_ENDPOINT_EDGE_TEMPLATES', API_ENDPOINT_EDGE_TEMPLATES)
  .constant('API_ENDPOINT_ENDPOINTS', API_ENDPOINT_ENDPOINTS)
  .constant('API_ENDPOINT_ENDPOINT_GROUPS', API_ENDPOINT_ENDPOINT_GROUPS)
  .constant('API_ENDPOINT_KUBERNETES', API_ENDPOINT_KUBERNETES)
  .constant('API_ENDPOINT_MOTD', API_ENDPOINT_MOTD)
  .constant('API_ENDPOINT_REGISTRIES', API_ENDPOINT_REGISTRIES)
  .constant('API_ENDPOINT_RESOURCE_CONTROLS', API_ENDPOINT_RESOURCE_CONTROLS)
  .constant('API_ENDPOINT_SETTINGS', API_ENDPOINT_SETTINGS)
  .constant('API_ENDPOINT_STACKS', API_ENDPOINT_STACKS)
  .constant('API_ENDPOINT_SUPPORT', API_ENDPOINT_SUPPORT)
  .constant('API_ENDPOINT_USERS', API_ENDPOINT_USERS)
  .constant('API_ENDPOINT_TAGS', API_ENDPOINT_TAGS)
  .constant('API_ENDPOINT_TEAMS', API_ENDPOINT_TEAMS)
  .constant('API_ENDPOINT_TEAM_MEMBERSHIPS', API_ENDPOINT_TEAM_MEMBERSHIPS)
  .constant('API_ENDPOINT_TEMPLATES', API_ENDPOINT_TEMPLATES)
  .constant('API_ENDPOINT_WEBHOOKS', API_ENDPOINT_WEBHOOKS)
  .constant('PAGINATION_MAX_ITEMS', PAGINATION_MAX_ITEMS)
  .constant('APPLICATION_CACHE_VALIDITY', APPLICATION_CACHE_VALIDITY)
  .constant('CONSOLE_COMMANDS_LABEL_PREFIX', CONSOLE_COMMANDS_LABEL_PREFIX)
  .constant('PREDEFINED_NETWORKS', PREDEFINED_NETWORKS)
  .constant('BROWSER_OS_PLATFORM', BROWSER_OS_PLATFORM).name;
