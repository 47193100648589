const autoNgTemplateLoaderTemplate1 = require('./BEFeatureIndicator.html');

import controller from './BEFeatureIndicator.controller';

export const beFeatureIndicator = {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller,
  bindings: {
    feature: '<',
  },
  transclude: true,
};
