const autoNgTemplateLoaderTemplate1 = require('./ldap-settings-security.html');

export const ldapSettingsSecurity = {
  templateUrl: autoNgTemplateLoaderTemplate1,
  bindings: {
    settings: '=',
    tlscaCert: '<',
    onTlscaCertChange: '<',
    uploadInProgress: '<',
    title: '@',
    limitedFeatureId: '<',
  },
};
