const autoNgTemplateLoaderTemplate1 = require('./ldap-connectivity-check.html');

export const ldapConnectivityCheck = {
  templateUrl: autoNgTemplateLoaderTemplate1,
  bindings: {
    settings: '<',
    state: '<',
    connectivityCheck: '<',
    limitedFeatureId: '<',
  },
};
