const autoNgTemplateLoaderTemplate1 = require('./createApplication.html');

angular.module('portainer.kubernetes').component('kubernetesCreateApplicationView', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: 'KubernetesCreateApplicationController',
  controllerAs: 'ctrl',
  bindings: {
    endpoint: '<',
  },
});
