const autoNgTemplateLoaderTemplate1 = require('./pagination.html');

export const datatablePagination = {
  bindings: {
    onChangeLimit: '<',
    limit: '<',
    enableNoLimit: '<',
    onChangePage: '<',
  },
  templateUrl: autoNgTemplateLoaderTemplate1,
};
