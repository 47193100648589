const autoNgTemplateLoaderTemplate1 = require('./editEdgeStackView.html');

import angular from 'angular';

import { EditEdgeStackViewController } from './editEdgeStackViewController';

angular.module('portainer.edge').component('editEdgeStackView', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: EditEdgeStackViewController,
});
