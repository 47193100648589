const autoNgTemplateLoaderTemplate1 = require('./customTemplatesView.html');

import CustomTemplatesViewController from './customTemplatesViewController.js';

angular.module('portainer.app').component('customTemplatesView', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: CustomTemplatesViewController,
  bindings: {
    endpoint: '<',
  },
});
