const autoNgTemplateLoaderTemplate1 = require('./addProfile.html');

import angular from 'angular';

import controller from './addProfileController';

angular.module('portainer.app').component('addProfileView', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller,
});
