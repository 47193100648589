const autoNgTemplateLoaderTemplate1 = require('./resourcePoolsDatatable.html');

angular.module('portainer.kubernetes').component('kubernetesResourcePoolsDatatable', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: 'KubernetesResourcePoolsDatatableController',
  bindings: {
    restrictDefaultNamespace: '<',
    titleText: '@',
    titleIcon: '@',
    dataset: '<',
    tableKey: '@',
    orderBy: '@',
    reverseOrder: '<',
    removeAction: '<',
    refreshCallback: '<',
  },
});
