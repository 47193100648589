const autoNgTemplateLoaderTemplate1 = require('./stats.html');

angular.module('portainer.kubernetes').component('kubernetesNodeStatsView', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: 'KubernetesNodeStatsController',
  controllerAs: 'ctrl',
  bindings: {
    $transition$: '<',
  },
});
