const autoNgTemplateLoaderTemplate1 = require('./registry-form-gitlab.html');

angular.module('portainer.app').component('registryFormGitlab', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  bindings: {
    model: '=',
    retrieveRegistries: '<',
    createRegistries: '<',
    actionInProgress: '<',
    projects: '=',
    state: '=',
    resetDefaults: '<',
  },
});
