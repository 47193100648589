const autoNgTemplateLoaderTemplate1 = require('./oauth-providers-selector.html');

import angular from 'angular';
import controller from './oauth-provider-selector.controller';

angular.module('portainer.oauth').component('oauthProvidersSelector', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  bindings: {
    onChange: '<',
    value: '<',
  },
  controller,
});
