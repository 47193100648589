const autoNgTemplateLoaderTemplate1 = require('./productList.html');

angular.module('portainer.app').component('productList', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  bindings: {
    titleText: '@',
    products: '<',
    goTo: '<',
  },
});
