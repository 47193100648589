const autoNgTemplateLoaderTemplate1 = require('./strings-datatable.html');

import angular from 'angular';
// import controller from './strings-datatable.controller.js';

export const stringsDatatable = {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: 'GenericDatatableController',
  bindings: {
    titleText: '@',
    titleIcon: '@',
    dataset: '<',
    emptyDatasetMessage: '@',

    columnHeader: '@',
    tableKey: '@',

    onRemove: '<',
  },
};

angular.module('portainer.app').component('stringsDatatable', stringsDatatable);
