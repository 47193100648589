const autoNgTemplateLoaderTemplate1 = require('./container-restart-policy.html');

angular.module('portainer.docker').component('containerRestartPolicy', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: 'ContainerRestartPolicyController',
  bindings: {
    name: '<',
    maximumRetryCount: '<',
    updateRestartPolicy: '&',
  },
});
