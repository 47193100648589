const autoNgTemplateLoaderTemplate1 = require('./porAccessControlForm.html');

angular.module('portainer.app').component('porAccessControlForm', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: 'porAccessControlFormController',
  bindings: {
    // This object will be populated with the form data.
    // Model reference in porAccessControlFromModel.js
    formData: '=',
    // Optional. An existing resource control object that will be used to set
    // the default values of the component.
    resourceControl: '<',
    hideTitle: '<',
  },
});
