const autoNgTemplateLoaderTemplate1 = require('./host-details-panel.html');

angular.module('portainer.docker').component('hostDetailsPanel', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  bindings: {
    host: '<',
    isBrowseEnabled: '<',
    browseUrl: '@',
  },
});
