const autoNgTemplateLoaderTemplate1 = require('./ldap-user-search.html');

import controller from './ldap-user-search.controller';

export const ldapUserSearch = {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller,
  bindings: {
    settings: '=',
    domainSuffix: '@',
    showUsernameFormat: '<',
    baseFilter: '@',
    limitedFeatureId: '<',

    onSearchClick: '<',
  },
};
