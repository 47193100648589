const autoNgTemplateLoaderTemplate1 = require('./createRegistry.html');

import angular from 'angular';
import CreateRegistryController from './createRegistryController';

angular.module('portainer.app').component('createRegistry', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: CreateRegistryController,
  bindings: {
    $transition$: '<',
  },
});
