const autoNgTemplateLoaderTemplate1 = require('./host-overview.html');

angular.module('portainer.docker').component('hostOverview', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  bindings: {
    hostDetails: '<',
    engineDetails: '<',
    devices: '<',
    disks: '<',
    isAgent: '<',
    agentApiVersion: '<',
    refreshUrl: '@',
    browseUrl: '@',
    hostFeaturesEnabled: '<',
  },
  transclude: true,
});
