const autoNgTemplateLoaderTemplate1 = require('./datatable-filter.html');

import controller from './datatable-filter.controller';

export const datatableFilter = {
  bindings: {
    labels: '<', // [{label, value}]
    state: '<', // [filterValue]
    filterKey: '@',
    onChange: '<',
  },
  controller,
  templateUrl: autoNgTemplateLoaderTemplate1,
  transclude: true,
};
