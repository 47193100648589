const autoNgTemplateLoaderTemplate1 = require('./helm.html');

import angular from 'angular';
import controller from './helm.controller';
import './helm.css';

angular.module('portainer.kubernetes').component('kubernetesHelmApplicationView', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller,
  bindings: {
    endpoint: '<',
  },
});
