const autoNgTemplateLoaderTemplate1 = require('./InformationPanelAngular.html');

export const InformationPanelAngular = {
  templateUrl: autoNgTemplateLoaderTemplate1,
  bindings: {
    titleText: '@',
    dismissAction: '&?',
  },
  transclude: true,
};
