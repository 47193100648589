const autoNgTemplateLoaderTemplate1 = require('./helm-templates-list.html');

import angular from 'angular';
import controller from './helm-templates-list.controller';

angular.module('portainer.kubernetes').component('helmTemplatesList', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller,
  bindings: {
    loading: '<',
    titleText: '@',
    charts: '<',
    tableKey: '@',
    selectAction: '<',
  },
});
