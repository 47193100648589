const autoNgTemplateLoaderTemplate1 = require('./configMap.html');

angular.module('portainer.kubernetes').component('kubernetesConfigMapView', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: 'KubernetesConfigMapController',
  controllerAs: 'ctrl',
  bindings: {
    $transition$: '<',
  },
});
