const autoNgTemplateLoaderTemplate1 = require('./editProfile.html');

import angular from 'angular';

import controller from './editProfileController';

angular.module('portainer.app').component('editProfileView', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller,
});
