const autoNgTemplateLoaderTemplate1 = require('./networkMacvlanForm.html');

angular.module('portainer.docker').component('networkMacvlanForm', {
  templateUrl: autoNgTemplateLoaderTemplate1,
  controller: 'NetworkMacvlanFormController',
  bindings: {
    data: '=',
    applicationState: '<',
  },
});
